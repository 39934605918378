import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import randomName from "../script/default_name";
import DAny from "./dice/DAny";

// I think the different dice buttons should only collapse into the dropdown
// if the screen size is small. Otherwise it should just show the individual
// dice

const defaultName = randomName();

const menuStyle = {
  minWidth: "2em",
  textAlign: "center",
  backgroundColor: "transparent",
  border: "none",
};

const die_list = [
  { die: "d20", color: "blue" },
  { die: "d12", color: "green" },
  { die: "d10", color: "red" },
  { die: "d8", color: "purple" },
  { die: "d6", color: "brown" },
  { die: "d4", color: "darkgray" },
];

const randoColorHex = function () {
  let colorParts = Array(3).fill("");
  let colorString = colorParts
    .map(() => {
      return Math.floor(Math.random() * 100)
        .toString(16)
        .padStart(2, "0");
    })
    .join("");
  // console.log(`#${colorString}`);
  return `#${colorString}`;
};

const Footer = ({ onThrowDie }) => {
  const [name, setName] = useState(localStorage.getItem("name") || defaultName);
  const [color, setColor] = useState(
    localStorage.getItem("color") || randoColorHex()
  );
  const [lastDie, setLastDie] = useState("d20");

  useEffect(() => {
    localStorage.setItem("name", name);
  }, [name]);
  useEffect(() => {
    localStorage.setItem("color", color);
  }, [color]);

  return (
    <footer className="fixed-bottom bg-primary">
      <Container fluid>
        <Row className="align-items-center">
          <Col>
            <Form.Control
              className="me-auto"
              placeholder="Character Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Col>
          <Col xs="auto">
            <Form.Control
              type="color"
              id="exampleColorInput"
              defaultValue={color}
              title="Pick a color"
              onChange={(e) => {
                setColor(e.target.value);
              }}
            />
          </Col>
          <Col xs="auto">
            <Dropdown as={ButtonGroup}>
              <Button
                variant="success"
                onClick={() => {
                  onThrowDie(name, lastDie, color);
                }}
              >
                <DAny
                  die={lastDie}
                  size={60}
                  facecount
                />
              </Button>
              <Dropdown.Toggle
                split
                variant="warning"
                id="dropdown-split-basic"
              />
              <Dropdown.Menu style={menuStyle}>
                {die_list.map((die_obj) => {
                  return (
                    <Dropdown.Item
                      className="dice-dropdown-item rounded-circle"
                      key={die_obj.die}
                      style={{ color: die_obj.color }}
                      onClick={() => {
                        setLastDie(die_obj.die);
                        onThrowDie(name, die_obj.die, color);
                      }}
                    >
                      <DAny
                        die={die_obj.die}
                        size={50}
                        facecount
                      />
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
