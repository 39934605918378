import React from "react";
import "./dice.css";

const value_dict = {
  1: "A",
  2: "B",
  3: "C",
  4: "D",
  5: "E",
  6: "F",
  7: "G",
  8: "H",
  9: "I",
  10: "J",
  11: "K",
  12: "L",
  13: "M",
  14: "N",
  15: "O",
  16: "P",
  17: "Q",
  18: "R",
  19: "S",
  20: "T",

  0: '"',
};

const D20 = ({ value, size, facecount, className }) => {
  if (facecount) value = 20;
  className = `die-char dice-d20 ${className? className : ""}`

  return (
    <span
    className={className}
      style={{
        fontSize: `${size}px`,
      }}
    >
      {value_dict[value]}
    </span>
  );
};

export default D20;
