import React from "react";
import "./dice.css";

const value_dict = {
  1: "!",
  2: "@",
  3: "#",
  4: "$",
};

const D4 = ({ value, size, facecount, className }) => {
  if (facecount) value = 4;
  className = `die-char dice-d4 ${className}`

  return (
    <span
      className={className}
      style={{
        fontSize: `${size}px`,
      }}
    >
      {value_dict[value]}
    </span>
  );
};

export default D4;
