import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { GiDiceTwentyFacesTwenty } from "react-icons/gi";
// import DAny from "./dice/DAny";

const Header = ({ appName, roomName }) => {
  return (
    <Navbar
      className="justify-content-between"
      collapseOnSelect
      expand="lg"
      bg="dark"
      variant="dark"
    >
      <Navbar.Brand>
        <GiDiceTwentyFacesTwenty size="36px" /> {appName}
      </Navbar.Brand>
      <Navbar.Brand className="text-muted">{roomName}</Navbar.Brand>
      {/* 
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />

      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="#features">Features</Nav.Link>
          <Nav.Link href="#pricing">Pricing</Nav.Link>
          <NavDropdown
            title="Dropdown"
            id="collasible-nav-dropdown"
          >
            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">
              Another action
            </NavDropdown.Item>
            <NavDropdown.Item>
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Cool Mode"
              />
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">
              Separated link
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <Nav>
          <Nav.Link href="#deets">More deets</Nav.Link>
          <Nav.Link
            eventKey={2}
            href="#memes"
          >
            Dank memes
          </Nav.Link>
        </Nav>
      </Navbar.Collapse> */}
    </Navbar>
  );
};

export default Header;
