import React from "react";
import "./dice.css";

const value_dict = {
  1: "A",
  2: "B",
  3: "C",
  4: "D",
  5: "E",
  6: "F",
};

const D6 = ({ value, size, facecount, className }) => {
  if (facecount) value = 6;
  className = `die-char dice-d6 ${className? className : ""}`

  return (
    <span
    className={className}
      style={{
        fontSize: `${size}px`,
      }}
    >
      {value_dict[value]}
    </span>
  );
};

export default D6;
