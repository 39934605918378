import React from "react";
import "./dice.css";

const value_dict = {
  0: "A",
  1: "B",
  2: "C",
  3: "D",
  4: "E",
  5: "F",
  6: "G",
  7: "H",
  8: "I",
  9: "J",
  10: "L",
};

const D10 = ({ value, size, facecount, className }) => {
  if (facecount) value = 10;
  className = `die-char dice-d10 ${className? className : ""}`

  return (
    <span
    className={className}
      style={{
        fontSize: `${size}px`,
      }}
    >
      {value_dict[value]}
    </span>
  );
};

export default D10;
