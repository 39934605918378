import React from "react";
import Stack from "react-bootstrap/Stack";
import ThrowInfo from "./ThrowInfo";

const Main = ({ rolls }) => {
  return (
    <main role="main">
      <Stack
        gap={3}
        className="App flex-column-reverse"
      >
        {rolls.map((roll, dex) => (
          <ThrowInfo
            key={dex}
            name={roll.name}
            die={roll.die}
            value={roll.value}
            color={roll.color}
          />
        ))}
      </Stack>
    </main>
  );
};

export default Main;
