import React from "react";
import "./dice.css";

const value_dict = {
  1: "A",
  2: "B",
  3: "C",
  4: "D",
  5: "E",
  6: "F",
  7: "G",
  8: "H",
};

const D8 = ({ value, size, facecount, className }) => {
  if (facecount) value = 8;
  className = `die-char dice-d8 ${className? className : ""}`

  return (
    <span
    className={className}
      style={{
        fontSize: `${size}px`,
      }}
    >
      {value_dict[value]}
    </span>
  );
};

export default D8;
