import React from "react";
import CharName from "./CharName";
import DAny from "./dice/DAny";

const ThrowInfo = ({ name, die, value, color }) => {
  return (
    <span
      className="bg-light border  d-flex flex-row justify-content-between"
      style={{
        alignItems: "center",
      }}
    >
      <span className="h3">
        <CharName
          name={name}
          color={color}
        />
      </span>
      <span className=" d-flex flex-row flex-wrap text-dark">
        {value.map((v, dex) => {
          return (
            <DAny
              key={dex}
              die={die}
              value={v}
              size={45}
            />
          );
        })}
      </span>
      <span className="text-muted">({die})</span>
    </span>
  );
};

export default ThrowInfo;
