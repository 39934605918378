import React from "react";
import D4 from "./D4";
import D6 from "./D6";
import D8 from "./D8";
import D10 from "./D10";
import D12 from "./D12";
import D20 from "./D20";

// Die that changes look based on properties

const die_map = {
  d4: D4,
  d6: D6,
  d8: D8,
  d10: D10,
  d12: D12,
  d20: D20,
};

const DAny = ({ die, value, size, facecount, className }) => {
  const DieType = die_map[die];
  className = `rounded-circle ${className? className : ""}`

  if (facecount)
    return (
      <DieType
        className={className}
        size={size}
        facecount
      />
    );
  else
    return (
      <DieType
      className={className}
        value={value}
        size={size}
      />
    );
};

export default DAny;
