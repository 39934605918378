import { useEffect, useState, useCallback } from "react";
import Stack from "react-bootstrap/Stack";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Main from "./components/Main";
import config from "./config";

const RoomPath = window.location.pathname;

const roomName = window.location.pathname.split("/").pop();

let eventSource = null;

function App() {
  const [appName, setAppName] = useState("Throw Dice With Buds");
  const [rolls, setRolls] = useState([]);

  const addNewThrowEvent = useCallback((throwData) => {
    setRolls((oldRolls) => {
      let newRolls = [...oldRolls];

      const match =
        newRolls.length &&
        newRolls[newRolls.length - 1].name === throwData.name &&
        newRolls[newRolls.length - 1].die === throwData.die;

      if (match) {
        let lastRoll = newRolls.pop();
        let newLastRoll = {
          name: throwData.name,
          die: throwData.die,
          value: [...lastRoll.value, throwData.value],
          color: throwData.color,
        };
        newRolls.push(newLastRoll);
      } else {
        newRolls.push({
          name: throwData.name,
          die: throwData.die,
          value: [throwData.value],
          color: throwData.color,
        });
      }

      return newRolls;
    });
  }, []);

  useEffect(() => {
    if (eventSource === null) {
      // console.log("Constructing new EventSource.");
      eventSource = new EventSource(`${config.APIBaseURL}${RoomPath}/stream`);
      eventSource.onmessage = (e) => addNewThrowEvent(JSON.parse(e.data));
    }
  }, [addNewThrowEvent]);

  const throwDie = (name, die, color) => {
    const data = {
      name,
      die,
      color,
    };

    fetch(`${config.APIBaseURL}${RoomPath}/throw`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.json();
      })
      .catch((err) => {
        console.log(`Error POSTing throw: ${err}`);
      });
  };

  useEffect(() => {
    const eventSource = new EventSource(`${config.APIBaseURL}/name`);
    eventSource.onmessage = (e) => setAppName(JSON.parse(e.data).name);
    return () => {
      eventSource.close();
    };
  }, []);

  return (
    <Stack>
      <Header
        appName={appName}
        roomName={roomName}
      />
      <Main rolls={rolls} />
      <Footer onThrowDie={throwDie} />
    </Stack>
  );
}

export default App;
